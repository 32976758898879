import React from "react";
import "./HeaderTemplate.css";

export default function HeaderTemplate({projects}) {
 
const uniqueNames = [...new Set(projects.map((item) => item.name))];
  const groupByName = (arr) => {
  return arr.reduce((acc, item) => {
    if (!acc[item.name]) {
      acc[item.name] = [];
    }
    acc[item.name].push(item);
    return acc;
  }, {});
};
console.log(groupByName(projects));
  const grouped = groupByName(projects);
  return (
    <nav
      className="navbar navbar-expand-lg fixed-top border-bottom border-body"
      data-bs-theme="dark" style={{background:"black"}}
    >
      <div className="container-fluid">
        <a className="navbar-brand h1" href="/" style={{ marginLeft: "50px" }}>
          <img
            src="../../images/home/Logo.jpeg"
            alt="Bootstrap"
            width="30"
            height="30"
            style={{marginBottom:"5px"}}
          ></img>
          JRAYAS ARCHITECTS
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className="navbar-nav   mx-auto mb-2 mb-lg-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "60px",
            }}
          >
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Home
              </a>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                Projects
              </a>

              <ul className="dropdown-menu">
                {uniqueNames.map((item) => (
                  <div className="dropdown dropend">
                    <li
                      className="dropdown-toggle dropdown-item"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {item}
                    </li>
                    <ul className="dropdown-menu ">
                      {grouped[item].length > 0 ? (
                        grouped[item].map((project) => (
                          <li className="dropdown-item">
                            <a
                              href={`/project/${project.name}/${project.subname}`}
                              underline="none"
                            >
                              {project.subname}
                            </a>
                          </li>
                        ))
                      ) : (
                        <li></li>
                      )}
                    </ul>
                  </div>
                ))}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="/service/architecturalService"
                  >
                    Architectural Service
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/interiorService">
                    Interior Service
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/service/modularInteriorService"
                  >
                    Modular Service
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/landscapeService">
                    Landscape Service
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/service/buildingConstructionService"
                  >
                    Building Construction Service
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/pmcService">
                    Project Management Service
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-disabled="true">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="/contact" aria-disabled="true">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
