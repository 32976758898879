import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ExperienceListTemplate from "../ExperienceListTemplate/ExperienceListTemplate.component";
import HomeTemplate from "../HomeTemplate/HomeTemplate.component";
import IndividualServiceTemplate from "../IndividualServiceTemplate/IndividualServiceTemplate";
import IndividualProject from "../IndividualProject/IndividualProject";
import ContactUsTemplate from "../ContactUsTemplate/ContactUsTemplate";

export const HomePage = () => {
  return (
    <Routes>
      <Route path="/" Component={HomeTemplate} />
      <Route path="/experience" Component={ExperienceListTemplate} />
      <Route path="/service/*" Component={IndividualServiceTemplate} />
      <Route path="/project/*" Component={IndividualProject}  />
      <Route path="/contact/*" Component={ContactUsTemplate} />
    </Routes>
  );
};

export default HomePage;
