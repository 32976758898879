import React from 'react'
import "./ServicesTemplate.css";
import { useLocation, useNavigate } from "react-router-dom";
export const ServicesTemplate = () => {
   const navigate = useNavigate();
  const buttonClick=()=>{
  const cardLinks = document.querySelectorAll(".card-links button");
  cardLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const target = e.target.getAttribute("data-target");
      const targetCard = document.getElementById(target);

      if (targetCard) {
        // Remove the "active" class from all links
        cardLinks.forEach((l) => l.classList.remove("active"));

        // Add the "active" class to the clicked link
        e.target.classList.add("active");

        // Scroll to the target card
        targetCard.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    });
  });
}
  return (
    <div className='bg-body-tertiary' style={{paddingTop: "39px",
    paddingBottom: "10px",
    paddingRight: "12px"}}>
    
    <div className='row'style={{overflow:"auto"}}>
      <div className='col'>
      <div id="cardcheck"style={{display:'-webkit-box',
    width: "400px",gap:"30px"
}}>
        
            <div className="card text-bg-dark"  id="card1"  >
              
  <img src="/images/Services/2.architecture.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">Architectural Services</h5>
    <p className="card-text">At JRAYAS ARCHITECTS we're passionate about shaping the world through innovative and sustainable
architectural solutions.</p>
                <button onClick={()=>{navigate("/service/architecturalService")}}>Read More</button>
                </div>
          </div>
               <div className="card text-bg-dark" >
  <img src="/images/Services/interiors.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">Interior Services</h5>
    <p className="card-text">At JRAYAS ARCHITECTS ,we believe that interiors should reflect your personality, improve functionality, and
evoke emotions.</p>
                <button onClick={()=>{navigate("/service/interiorService")}}>Read More</button>
          </div>
          </div>
          <div className="card text-bg-dark" id='card3'>
  <img src="/images/Services/modular.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">Modular Interiors</h5>
    <p className="card-text">JRAYAS ARCHITECTS , we understand that the way we live, work, and interact with our spaces is constantly
evolving. </p>
                <button onClick={()=>{navigate("/service/modularInteriorService")}}>Read More</button>
          </div>
        </div>
        <div className="card text-bg-dark" >
  <img src="/images/Services/landscape.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">Landscape service</h5>
    <p className="card-text">At JRAYAS ARCHITECTS ,we believe that the great outdoors should be just as captivating as the indoors. Our
landscape works are a testament to our commitment to creating outdoor spaces</p>
                 <button onClick={()=>{navigate("/service/landscapeService")}}>Read More</button>
          </div>
          </div>
          <div className="card text-bg-dark" >
  <img src="/images/Services/construction.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">Building Construction</h5>
    <p className="card-text">At JRAYAS ARCHITECTS, we specialize in building construction that marries innovation, quality, and functionality.
Our unwavering commitment to excellence has made us a trusted partner </p>

                 <button onClick={()=>{navigate("/service/buildingConstructionService")}}>Read More</button>
          </div>
          </div>
            <div className="card text-bg-dark"  id='card6' >
              
  <img src="/images/Services/pmc.jpg" className="card-img" alt="..."/>
  <div className="card-img-overlay" style={{background:"rgba(0, 0, 0, 0.5)"}}>
    <h5 className="card-title">PMC</h5>
    <p className="card-text">At JRAYAS ARCHITECTS, we understand that successful construction and interior projects require meticulous
planning, precise execution, and expert oversight.</p>
                <button onClick={()=>{navigate("/service/pmcService")}}>Read More</button>
                
                </div>
          </div>
          
          
          
                   
                        
            
            </div>
          </div>
        </div>
        
  <div className="card-links" style={{display:"flex",justifyContent:"center"}}>
    <button data-target="card1"onClick={buttonClick} style={{fontSize:"50px"}}> .</button>
        <button data-target="card3" onClick={buttonClick} style={{ fontSize: "50px" }}>.</button>
    <button data-target="card6" onClick={buttonClick} style={{fontSize:"50px"}}>.</button>
    
  
  </div>

      
      </div>
  )
}
export default ServicesTemplate
