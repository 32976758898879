import React from "react";
import "./FirstTemplate.css";
export default function FirstTemplate() {
  const homeDetails=[
    
    {
        "image":"images/home/firstImage.jpg",
        "title":"Beautiful designs with modern technology"

    },{
        "image":"images/home/MainScreen.jpg",
        "title":"Beautiful designs with modern technology"

    }
]
  return (
    <div className="" style={{marginTop:"60px"}}>
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-container"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner ">
       {homeDetails?.map((item)=>(<div className="carousel-item active" data-bs-interval="3000">
          <img src={item.image} className="custom-image-md" style={{height:"810px"}}  alt="..." />
          <div className="carousel-caption text-container custom-margin-md d-lg-block" >
           <h1 className="caption-title">{item.title}</h1>
           <h5 className="caption">{ item.caption}</h5>
      </div>
        </div>
        ))} 
        
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
      </div>
      </div>
  );
}
