import React from "react";
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate.component";
import FirstTemplate from "../FirstTemplate/FirstTemplate.component";
import ExperienceListTemplate from "../ExperienceListTemplate/ExperienceListTemplate.component";
import TotalEstimationTemplate from "../TotalEstimationTemplate/TotalEstimationTemplate.component";
import AllProjectsTemplate from "../AllProjectsTemplate/AllProjectsTemplate.component";
import FooterTemplate from "../FooterTemplate/FooterTemplate.component";
import ServicesTemplate from "../ServicesTemplate/ServicesTemplate.component";
import AboutUsTemplate from "../AboutUsTemplate/AboutUsTemplate";
import TeamTemplate from "../TeamTemplate/TeamTemplate";
import IndividualProject from "../IndividualProject/IndividualProject";

function HomeTemplate() {
  const projects = [
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project1/1.HOUSE FASAD DESIGN_CA_Mr.Bale Shiva Prasad_.jpg",
        },
        {
          src: "images/projects/Residential/project1/1.LIVING ROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/2.HOUSE FASAD DESIGN_CA_Mr.Bale Shiva Prasad_.jpg",
        },
        {
          src: "images/projects/Residential/project1/2.TV UNIT.jpg",
        },
        {
          src: "images/projects/Residential/project1/3.KITCHEN.jpg",
        },
        {
          src: "images/projects/Residential/project1/3A.KITCHEN.jpg",
        },
        {
          src: "images/projects/Residential/project1/3B.KITCHEN.jpg",
        },
        {
          src: "images/projects/Residential/project1/4.DRAWING ROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/5.BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/6A.BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/6B.BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/6C.BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/7.CEILING BEDROOM.jpg",
        },

        {
          src: "images/projects/Residential/project1/8.GUEST BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/8A.GUEST BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/8B.GUEST BEDROOM.jpg",
        },
        {
          src: "images/projects/Residential/project1/9.GUEST BEDROOM GALLERY WALL.jpg",
        },
      ],
      subname: "project1",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Kondapur,Hyderabad",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project2/1.living.jpg",
        },
        {
          src: "images/projects/Residential/project2/2.living.jpg",
        },
        {
          src: "images/projects/Residential/project2/3.kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project2/4.dining.jpg",
        },

        {
          src: "images/projects/Residential/project2/5.dining.jpg",
        },
        {
          src: "images/projects/Residential/project2/6.dining hall.jpg",
        },

        {
          src: "images/projects/Residential/project2/6a.Master bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project2/7.Master Bedroom.jpg",
        },

        {
          src: "images/projects/Residential/project2/8.guest bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project2/9.guest bedroom.jpg",
        },

        {
          src: "images/projects/Residential/project2/10.Kids bedroom.jpg",
        },
      ],
      subname: "project2",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Kondapur,Hyderabad",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project3/1.Living room.jpg",
        },

        {
          src: "images/projects/Residential/project3/2.Living room.jpg",
        },
        {
          src: "images/projects/Residential/project3/3.Dining Room.jpg",
        },
        {
          src: "images/projects/Residential/project3/4.dining crockery.jpg",
        },
        {
          src: "images/projects/Residential/project3/5.Vanity.jpg",
        },
        {
          src: "images/projects/Residential/project3/6.Kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project3/7.Master Bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project3/8.Guest Bedroom.jpg",
        },
      ],
      type: "Turnkey project",
      designStyle: "Scandinavian",
      subname: "project3",
      location: "Kollur, Hyderabad",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project4/1._hajeepur_korutla.jpg",
        },
        {
          src: "images/projects/Residential/project4/1.korutla.jpg",
        },
        {
          src: "images/projects/Residential/project4/2.hajeepur_korutla.jpg",
        },
        {
          src: "images/projects/Residential/project4/2.korutla.jpg",
        },
      ],
      subname: "project4",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Hajeepur,Korutla",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project5/1.living room.jpg",
        },
        {
          src: "images/projects/Residential/project5/2.tv unit.jpg",
        },
        {
          src: "images/projects/Residential/project5/3.Dining.jpg",
        },
        {
          src: "images/projects/Residential/project5/4.dining.jpg",
        },
        {
          src: "images/projects/Residential/project5/5.Partition wall.jpg",
        },
        {
          src: "images/projects/Residential/project5/8.Living room.jpg",
        },
        {
          src: "images/projects/Residential/project5/9.Living room.jpg",
        },
        {
          src: "images/projects/Residential/project5/10.pooja room.jpg",
        },
        {
          src: "images/projects/Residential/project5/11.living_opt2.jpg",
        },
        {
          src: "images/projects/Residential/project5/12.Living_opt2.jpg",
        },
        {
          src: "images/projects/Residential/project5/13.Study room.jpg",
        },
        {
          src: "images/projects/Residential/project5/14.Study room.jpg",
        },
        {
          src: "images/projects/Residential/project5/15..Guest Bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project5/16..Master bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project5/17..Master bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project5/18..Master bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project5/19..Master bedroom.jpg",
        },
        {
          src: "images/projects/Residential/project5/20..Kids Room.jpg",
        },
        {
          src: "images/projects/Residential/project5/21..Kids Room.jpg",
        },
        {
          src: "images/projects/Residential/project5/22..Kids Room.jpg",
        },
        {
          src: "images/projects/Residential/project5/23.kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project5/24..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/25..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/26..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/27..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/28..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/29..outdoor.jpg",
        },
        {
          src: "images/projects/Residential/project5/30..outdoor.jpg",
        },
      ],
      subname: "project5",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Hajeepur,Korutla",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project7/F_classical elevation.jpg",
        },
        {
          src: "images/projects/Residential/project7/F_D_classical elevation.jpg",
        },
      ],
      subname: "project7",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Hajeepur,Korutla",
      yearBuilt: 2023,
    },
    {
      name: "Commercial",
      images: [
        {
          src: "images/projects/Commercial/project1/1.Hospital elevation.jpg",
        },
        {
          src: "images/projects/Commercial/project1/2.Hospital elevation.jpg",
        },
        {
          src: "images/projects/Commercial/project1/3.Hospital elevation.jpg",
        },
      ],
      subname: "project1",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Hajeepur,Korutla",
      yearBuilt: 2023,
    },
    {
      name: "Residential",
      images: [
        {
          src: "images/projects/Residential/project6/1.living & dinning.jpg",
        },
        {
          src: "images/projects/Residential/project6/2.living console.jpg",
        },
        {
          src: "images/projects/Residential/project6/3.living_main door.jpg",
        },

        {
          src: "images/projects/Residential/project6/4.Main living_1 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/5.Main living_2 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/6.Main living_3 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/7.Main living_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/8.Main living_5 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/9.Main living_6 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/10.living room 2_1 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/11.living room 2_2 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/12.living room 2_3 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/13.living room 2_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/14.bedroom_1_1 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/15.bedroom_1_2 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/16.bedroom_1_3 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/17.bedroom_1_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/18.bedroom_1_5 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/19.bedroom_1_6 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/20.jpg",
        },
        {
          src: "images/projects/Residential/project6/21.Bedroom_2_1 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/22.Bedroom_2_2 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/23.Bedroom_2_3 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/24.Bedroom_2_5 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/25.Bedroom_2_6 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/26.Bedroom_2_8 - Photo_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/27.Bedroom_2_8 - Photo_6 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/28.Bedroom_2_8 - Photo_7 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/29.child bedroom_1 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/30.child bedroom_2 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/31.child bedroom_3 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/32.child bedroom_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/33.child bedroom_5 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/34.child bedroom_6 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/35.masterbedroom.jpg",
        },
        {
          src: "images/projects/Residential/project6/36.Master bedroom_2 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/37.Master bedroom_3 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/38.Master bedroom_4 - Photo.jpg",
        },
        {
          src: "images/projects/Residential/project6/39.Master bedroom_5 - Photo.jpg",
        },

        {
          src: "images/projects/Residential/project6/40.kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project6/41.kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project6/42.kitchen.jpg",
        },
        {
          src: "images/projects/Residential/project6/43.kitchen.jpg",
        },
      ],
      subname: "project6",
      type: "Turnkey project",
      designStyle: "Scandinavian",

      location: "Hajeepur,Korutla",
      yearBuilt: 2023,
    },
  ];
  


  return (
    <div>
      <FirstTemplate />
      <ExperienceListTemplate />
      <ServicesTemplate />
      <TotalEstimationTemplate />

      <AllProjectsTemplate projects={projects} />
      <IndividualProject />
      <TeamTemplate />
      <AboutUsTemplate />
      <FooterTemplate />
    </div>
  );
}

export default HomeTemplate;
