import React from "react";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
export default function FooterTemplate() {
  return (
    <>
    <nav className="navbar fixed-bottom bg-body-tertiary  d-none d-sm-block d-md-block">
      <div className="col-md-12">
        <div class="row" >
                                    <div class="col-md-4" style={{display:"flex"}} >
                                        <div class="info-box padding20" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center"}} >
                                            <AccessTimeOutlinedIcon style={{height:"40px",width:"40px"}} />
                                            <div class="info-box_text"style={{paddingLeft:"20px"}} >
                                                <div style={{fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "16px",
    marginTop: "8px"}} >Opening Times</div>
                                                <div class="info-box_subtite" >Monday - Friday: 09:00 - 18:00</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4" >
                                        <div class="info-box padding20" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
                                        <a target="blank" href="https://maps.app.goo.gl/TCc94LFAMiWkCU8r7" style={{color:"black"}}>    <LocationOnOutlinedIcon  style={{height:"40px",width:"40px"}}/></a>
                                            <div class="info-box_text" style={{paddingLeft:"20px"}} >
                                                <div class="info-box_title"  style={{fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "16px",
    marginTop: "8px"}} >Our Location</div>
                                                <div class="info-box_subtite" >MPR Arcade, 71, Jubilee Enclave, HITEC City, Hyderabad,Telangana 500081.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4" style={{display:"flex"}}>
                                        <div class="info-box padding20" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
                                            <CallOutlinedIcon style={{height:"40px",width:"40px"}}/>
                                            <div class="info-box_text" style={{paddingLeft:"20px"}} >
                                                <div class="info-box_title" style={{fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "16px",
    marginTop: "8px"}} >Customer Support</div>
                                                <div class="info-box_subtite" >7799662477, 8099611980</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix" ></div>
                                </div>
        
          </div>
          {/* <div style={{ paddingLeft: "10px", background: "wheat"
} } >
      <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
        <AccessTimeOutlinedIcon/>
        <p style={{marginTop: "10px"}}>Monday - Saturday: 09:00 - 18:00</p>
      </div>
      <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
        <a href="https://maps.app.goo.gl/i3Krf3pchHER9mBfA" target="blank">
         <LocationOnOutlinedIcon/>
        </a>
        <p style={{marginTop: "10px"}}>
          MPR Arcade, 71, Jubilee Enclave, HITEC City, Hyderabad,Telangana
          500081.
        </p>
      </div>
      <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
        <CallOutlinedIcon/>
        <p style={{marginTop: "10px"}}>7799662477, 8099611980</p>
      </div>
    </div> */}
      </nav>
      
      </>
  );
}
