import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ExperienceListTemplate.css";
export const ExperienceListTemplate = () => {
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  React.useEffect(() => {
    const timer = setInterval(() => {
      if (count < 10) {
        setCount(count + 1);
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [count]);
  const moreInfo = [
    {
      title: "Architecture",
      subConcepts: [
        {
          heading: "Architectural Design: ",
          caption:
            "Our architects are dedicated to creating functional, aesthetically pleasing structures. Whether it's a new build, renovation, or addition, we design with precision and innovation.",
        },
        {
          heading: "Feasibility Studies: ",
          caption:
            "We conduct in-depth feasibility studies to assess the practicality of a project, considering factors like site constraints, budgets, and regulatory requirements.",
        },
        {
          heading: "Construction Documentation: ",
          caption:
            "Our detailed construction documents ensure that your project is executed with accuracy and quality, from blueprints to material specifications.",
        },
        {
          heading: "Project Management: ",
          caption:
            "We take on the responsibility of managing your project, overseeing construction, schedules, budgets, and quality control.",
        },
      ],
    },
    {
      title: "Interior Design",
      subConcepts: [
        {
          heading: "Space Planning: ",
          caption:
            "Our interior designers optimize the layout of your space, ensuring it's both functional and aesthetically pleasing.",
        },
        {
          heading: "Interior Styling: ",
          caption:
            "We bring your unique style to life with custom interior designs, tailored to your taste and lifestyle.",
        },
        {
          heading: "Furniture and Fixture Selection: ",
          caption:
            "Our team helps you select the perfect furniture, fixtures, and finishes to complete your interior design.",
        },
        {
          heading: "Color and Material Selection: ",
          caption:
            "From paint colors to flooring materials, we guide you in selecting the right elements to create a cohesive and harmonious space.",
        },
      ],
    },
    {
      title: "Residential Services",
      subConcepts: [
        {
          heading: "Custom Home Design: ",
          caption:
            "We specialize in designing dream homes, offering tailored design solutions that reflect your lifestyle and preferences.",
        },
        {
          heading: "Renovations and Additions: ",
          caption:
            "Transform your existing space with our renovation and addition services, making it more functional and appealing.",
        },
        {
          heading: "Kitchen and Bathroom Design: ",
          caption:
            "Our designers create stunning and functional kitchens and bathrooms that become the heart of your home.",
        },
      ],
    },
    {
      title: "Commercial Services",
      subConcepts: [
        {
          heading: "Office Design: ",
          caption:
            "Elevate your workplace with designs that promote productivity, collaboration, and employee satisfaction.",
        },
        {
          heading: "Retail and Hospitality: ",
          caption:
            "Create retail spaces and hospitality environments that engage customers and guests while reinforcing your brand identity.",
        },
      ],
    },
    {
      title: "Sustainable Design",
      subConcepts: [
        {
          heading: "Green Building Practices: ",
          caption:
            "We're committed to sustainability and incorporate eco-friendly building techniques, energyefficient systems, and sustainable materials into our designs.",
        },
        {
          heading: "LEED Certification: ",
          caption:
            "If you're interested in LEED certification, we can guide you through the process and design accordingly to meet the required standards",
        },
      ],
    },
    {
      title: "Project Consultation",
      subConcepts: [
        {
          heading: "Concept Development: ",
          caption:
            "We help you define the concept of your project, ensuring it aligns with your goals and vision.",
        },
        {
          heading: "Budget and Schedule Management: ",
          caption:
            "Our experts provide guidance on managing project budgets and schedules effectively.",
        },
      ],
    },
    {
      title: "3D Visualization",
      subConcepts: [
        {
          heading: "Architectural Renderings: ",
          caption:
            "We create stunning 3D architectural renderings that provide a realistic preview of your project.",
        },
        {
          heading: "Virtual Walkthroughs: ",
          caption:
            "Step inside your design and experience your space before construction begins with our virtual walkthroughs. We're dedicated to delivering exceptional results, exceeding your expectations, and making your project a success. Whether it's a residential, commercial, or sustainable project, we have the expertise and creativity to bring your ideas to life. Contact Us to discuss your project or Explore Our Portfolio to see examples of our completed work.",
        },
      ],
    },
  ];
  const others = [
    {
      title: "Our Portfolio: ",
      subTitle: "Transforming Spaces, Inspiring Lives",
      content:
        "Welcome to our curated gallery of architecture and interior design projects that tell the story of our commitment to creativity, innovation, and excellence. Each project represents a unique blend of form and function, reflecting our passion for crafting environments that inspire, captivate, and elevate the human experience. Explore our diverse portfolio and see how we've turned visions into reality.",
    },
    {
      title: "Residential Marvels: ",
      subTitle: "Where Dreams Find a Home",
      content:
        "Discover a world of elegant and functional residential designs that cater to the individual needs and desires of our clients. From luxurious, contemporary homes to cozy, traditional abodes, our architects and interior designers have the expertise to transform your house into a true haven. Each project is a testament to our ability to blend aesthetics with functionality, creating spaces that feel like home from the moment you step inside.",
    },
    {
      title: "Commercial Wonders: ",
      subTitle: "Elevating Your Business",
      content:
        "Our commercial projects showcase a fusion of innovation and practicality, tailored to the unique demands of businesses across various industries. We've designed cutting-edge office spaces that promote productivity, retail environments that captivate customers, and hospitality settings that create unforgettable experiences. Whether you're a start-up or an established brand, our designs add value and character to your commercial space.",
    },
    {
      title: "Sustainable Solutions: ",
      subTitle: "Designing for a Better Tomorrow",
      content:
        "At JRAYAS ARCHITECTS sustainability is not just a buzzword – it's a commitment. Explore our eco-friendly projects that showcase our dedication to minimizing environmental impact. From energy-efficient building designs to the use of sustainable materials and green technology, we integrate sustainable practices seamlessly into our projects, leaving a positive legacy for the environment and future generations.",
    },
    {
      title: "Renovations and Remodeling: ",
      subTitle: "Breathing New Life into Spaces",
      content: "Sometimes, all a space needs is a fresh perspective. Our renovation and remodeling projects demonstrate how we can breathe new life into existing structures, turning the old into the extraordinary. We're experts in preserving the essence of a space while infusing it with modern design elements and functionality.",
    },
    
  ];
  return (
    <div className="bg-dark " style={{ padding: "90px 0px 90px 0px " }}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              className="card bg-dark"
              style={{ border: "solid 2px rgba(255, 255, 255, .1)" }}
            >
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{
                    color: "white",

                    fontSize: "180px",
                    fontWeight: 800,
                  }}
                >
                  {count}
                </h5>
                <a
                  href="#"
                  className="btn text-white"
                  style={{
                    fontSize: "14px",
                    letterSpacing: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Years of Experience
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card bg-dark" style={{ border: "none" }}>
              <div className="card-body " style={{ padding: "48px" }}>
                <h5
                  className="card-title"
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    letterSpacing: "10px",
                    color: "white",
                  }}
                >
                  WELCOME
                </h5>
                <h2
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Your Best Partner for Architecture and Construction
                </h2>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card bg-dark" style={{ border: "none" }}>
              <div className="card-body text-white">
                <p style={{ textAlign: "left" }}>
                  Welcome to <i> JRAYAS ARCHITECTS</i>. where creativity meets
                  functionality, and dreams become reality. We are a passionate
                  team of architects and interior designers dedicated to
                  transforming spaces into timeless works of art. With a
                  commitment to innovation, aesthetics, and sustainability, we
                  take pride in crafting environments that inspire, captivate,
                  and elevate the human experience.
                  {useLocation().pathname === "/" && (
                    <button onClick={() => navigate("/experience")}>
                      More
                    </button>
                  )}
                </p>
              </div>
            </div>
          </div>
          {useLocation().pathname === "/experience" && (
            <div>
              <div style={{ padding: "28px" }}>
                <h3
                  style={{
                    color: "white",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  Our Approach:
                </h3>
                <p style={{ color: "white", textAlign: "left" }}>
                  At <i style={{ fontWeight: "bold" }}>JRAYAS ARCHITECTS </i> we
                  believe that every project is a unique canvas, waiting to be
                  adorned with the perfect blend of form and function. Our
                  holistic approach to design combines a deep understanding of
                  your vision, the latest industry trends, and our expertise to
                  create spaces that not only meet your needs but exceed your
                  expectations. Whether it's a residential oasis, a commercial
                  masterpiece, or a hospitality haven, we approach each project
                  with unwavering dedication.
                </p>
              </div>
              {moreInfo.map((item, index) => (
                <div style={{ padding: "28px" }}>
                  <h3
                    style={{
                      color: "white",
                      textAlign: "start",
                      fontWeight: "bold",
                    }}
                  >
                    {`${index + 1}. ${item.title}`}
                  </h3>
                  {item.subConcepts.map((subConcept) => (
                    <ul
                      style={{
                        color: "white",
                        textAlign: "start",
                      }}
                    >
                      <li>
                        <h4>{subConcept.heading} </h4>
                        <p>{subConcept.caption}</p>
                      </li>
                    </ul>
                  ))}
                </div>
              ))}
              <div style={{ padding: "28px" }}>
                <h3
                  style={{
                    color: "white",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  Why Choose Us :
                </h3>

                <ul
                  style={{
                    color: "white",
                    textAlign: "start",
                  }}
                >
                  <li>
                    <h4>Innovation : </h4>
                    <p>
                      We stay at the forefront of design trends and technology,
                      ensuring that our work is both current and timeless.
                    </p>
                  </li>
                  <li>
                    <h4>Collaboration : </h4>
                    <p>
                      We listen to your ideas and needs, working closely with
                      you to bring your vision to life.
                    </p>
                  </li>
                  <li>
                    <h4>Expertise : </h4>
                    <p>
                      With years of experience, our talented team has the
                      knowledge and skills to handle projects of all sizes and
                      complexities.
                    </p>
                  </li>
                  <li>
                    <h4>Client-Centric : </h4>
                    <p>
                      Our clients are at the heart of everything we do. We
                      strive to exceed expectations with every project.
                    </p>
                  </li>
                </ul>
                <h5
                  style={{
                    color: "white",
                    textAlign: "start",
                  }}
                >
                  Explore our portfolio to see the diverse range of projects
                  we've undertaken and get inspired. If you're ready to embark
                  on your next design journey, Contact Us and let's turn your
                  dreams into reality
                </h5>
              </div>
              <div style={{ padding: "28px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  <i>JRAYAS ARCHITECTS </i> – IT ALL BEGINS WITH ONE THOUGHT &
                  ONE STEP.
                </h2>
              </div>
              {others.map((item) => (
                <div style={{ padding: "28px" }}>
                  <div>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </h3>
                    <h4
                      style={{
                        color: "white",
                      }}
                    >
                      {item.subTitle}
                    </h4>
                  </div>
                  <h5
                    style={{
                      color: "white",
                      textAlign:"left"
                    }}
                  >
                    {item.content}
                  </h5>
                </div>
              ))}
              <div style={{ padding: "28px",color:"white" }}>
                <h3 style={{textAlign:"start",fontWeight:"bold"}}>Start Your Design Journey</h3>
                <h5 style={{ textAlign: "left", }}>
                  If you're ready to embark on your own design journey with us,
                  our portfolio serves as a source of inspiration. Get a taste
                  of what's possible when you partner with [Your Company Name].
                  We listen to your ideas, collaborate closely with you, and
                  bring your vision to life. Whether you're seeking an
                  architectural masterpiece or an interior design
                  transformation, our team is eager to create something special
                  for you. Each project in our portfolio represents a unique
                  challenge and a creative opportunity that we've embraced with
                  enthusiasm. Explore our gallery, and let these projects speak
                  for themselves. We look forward to adding your project to our
                  ever-growing list of success stories.
                  <br />
                  <b>
                    Contact Us to discuss your project, or Learn More about our
                    architecture and interior design services.
                  </b>
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExperienceListTemplate;
