import React, { useEffect } from 'react'
import "./AllProjectsTemplate.css"
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog } from '@mui/material';
import { useLocation } from 'react-router-dom';
//import fs from 'fs'
export const AllProjectsTemplate=({projects})=> {


  
  // const [isButtonClicked, setButtonClicked] = React.useState(false);
  const [individualProject, setIndividualProject] = React.useState([])
  const [subcategoryProjects,setSubcategoryProjects]=React.useState(projects)
  const [buttonName, setName] = React.useState()
  const [open, setOpen]=React.useState(false)
  // const buttonClick=()=>{
  // const cardLinks = document.querySelectorAll(".card-links button");
  // cardLinks.forEach((link) => {
  //   link.addEventListener("click", (e) => {
  //     e.preventDefault();
  //     const target = e.target.getAttribute("data-target");
  //     const targetCard = document.getElementById(target);

  //     if (targetCard) {
  //       // Remove the "active" class from all links
  //       cardLinks.forEach((l) => l.classList.remove("active"));

  //       // Add the "active" class to the clicked link
  //       e.target.classList.add("active");

  //       // Scroll to the target card
  //       targetCard.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //         inline: "start",
  //       });
  //     }
  //   });
  // });
  // }
  const mainButtonClick = (name) => {
    setName(name)
    if (name === "allProjects") {
      setSubcategoryProjects(projects)
    }
    else {
      const project = projects.filter((item) => {
        return item.name === name
      })
      setSubcategoryProjects(project)
    }
  }
  const buttons = [{
    name: "All Projects",
    type:"allProjects"
  },
    {
    name: "Residential",
    type: "Residential"
    },
    {
    name: "Hospitality",
    type: "Hospitality"
    },
    {
    name: "Office",
    type: "Office",
  },{
    name: "Commercial",
    type: "Commercial"
    }]
  
  const filterProject = (projectName, projectSubname) => {
    console.log(projectName,projectSubname)
    setOpen(true)
  const project=  projects.filter((item) => {
      return item.subname===projectSubname && item.name==projectName
  })
    setIndividualProject(project)
  }
   const [scroll, setScroll] = React.useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

 
    return (
    
      <div style={{background:"black"}}>
        <div style={{padding:"50px"}}>
          
          {buttons?.map((item) => (
            <button onClick={()=> mainButtonClick(item.type)} style={{
              background: (buttonName===item.type )? "wheat" : "none",
              outline: "none",
    padding: "10px 20px 8px 20px",
    color: "#888",
    fontSize: "11px",
    letterSpacing: "3px",
    textDecoration: "none",
    textTransform: "uppercase",
              border: "none !important",
              fontWeight: "bold",
            }}>{ item.name}</button>
          ))}
          
        </div>
      <div id="cardcheck"  style={{background:"black",gap:"30px",display:"flex",flexWrap:"wrap",    marginBottom: "30px",
    paddingBottom: "40px",
    paddingRight: "30px",
          paddingLeft: "30px",
    justifyContent:"center"
}}>
     {subcategoryProjects.map((project)=>( <div className="card text-bg-dark custom-card"  style={{width:"25rem",margin:"3px"}}>
       <img src={project.images[0].src} className="card-img custom-image " alt="..." />
  <div className="card-img-overlay overlay-text text">
       <button style={{background:"none",border:"none",color:"white",justifyContent:"center"}} onClick={()=>{filterProject(project.name,project.subname)}} > <h5 className="card-title ">{project.name}</h5></button> 
    
          </div>
     </div>))}
          {individualProject?.length > 0 && open &&
           <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              classes={{ paper: 'custom-dialog-paper' }}
               sx={{
        '& .MuiDialog-paper': {
                   width: '80%', // Adjust the width as needed
          maxWidth:"100%"
        },
      }}
      >
        <DialogTitle id="scroll-dialog-title" style={{alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "xx-large"}}><button onClick={handleClose} style={{background:"none",boxShadow:"none",border:"none",color:"white"}}> X</button></DialogTitle>
              <DialogContent dividers={scroll === 'paper'} style={{ background: "rgba(0, 0, 0, 0.5)"}}>
               
          <DialogContentText
            id="scroll-dialog-description"
                  tabIndex={-1}
                  
                >
          <div style={{ display: "flex",flexWrap:"wrap", color: "white", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              padding: "10px",
              width:"40rem"
            }}
          >
            {individualProject[0]?.images?.map((item, index) => (
              <img
                src={item.src}
                className="card-img"
                key={index}
              />
            ))}
          </div>
          <div style={{ padding: "10px" }}>
            <h3 style={{ fontWeight: "bold" }}>Projects Details</h3>
            <div
              style={{
                padding: "20px",
                border: "solid 1px rgba(255,255,255,.2)",
                background: "rgba(0,0,0,.5)",
                marginBottom: "30px",
              }}
            >
              <div style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>Name: </span>
                <span>{individualProject[0]?.name}</span>
              </div>
              <div style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>Type: </span>
                <span>{individualProject[0]?.type}</span>
              </div>
              <div style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>Design Style: </span>
                <span>{individualProject[0]?.designStyle}</span>
              </div>
              <div style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>Location: </span>
                <span>{individualProject[0]?.location}</span>
              </div>
              <div style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>Built Year: </span>
                <span>{individualProject[0]?.yearBuilt}</span>
              </div>
            </div>
          </div>
        </div>
                    
            
               
                </DialogContentText>
                  
        </DialogContent>
       
      </Dialog>}
         
        </div>
        </div>
                

 
    

  )
}
export default AllProjectsTemplate
