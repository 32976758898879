import React from "react";
import "./TeamTemplate.css";
function TeamTemplate() {
  const teamMates = [
    {
      img: "images/teamMembers/SRINIVAS_FOUNDER_CHIEF ARCHITECT.jpeg",
      name: "Ar. KODAM SRINIVAS",
      position: "FOUNDER & CHIEF ARCHITECT",
      link: "http://linkedin.com/in/srinivas-k-3b5aa1b5",
    },
    {
      img: "images/teamMembers/azaruddin_FOUNDER_principal architect.jpeg",
      name: "Ar. MOHAMMAD AZARUDDIN",
      position: "FOUNDER & PRINCIPAL ARCHITECT",
    },
    {
      img: "images/teamMembers/ASIYA_ARCHITECT_INTERIOR DESIGNER.jfif",
      name: "Ar. ASIYA",
      position: "ARCHITECT",
    },
    {
      img: "images/teamMembers/NIKHIL_SITE ENGINEER.jfif",
      name: "NIKHIL ERPENIWAR",
      position: "SITE ENGINEER",
    },
    {
      img: "images/teamMembers/fayaz_architecct.jpeg",
      name: "Ar. FAYAZ",
      position: "ARCHITECT",
    },
    {
      img: "images/teamMembers/RAJU_LANDSCAPE ARCHITECT.jfif",
      name: "Ar. RAJU",
      position: "LANDSCAPE ARCHITECT",
    },
    {
      img: "images/teamMembers/MADHUSUDHAN_MEP.jfif",
      name: "MADHUSUDHAN RAO",
      position: "MEP",
    },
    {
      img: "images/teamMembers/PRANAY_STRUCTURAL ENGINEER.jfif",
      name: "PRANAY",
      position: "CIVIL ENGINEER",
    },
    {
      img: "images/teamMembers/RANJAN_3D VISULASER.jfif",
      name: "RANJAN",
      position: "3D VISUALIZER",
    },
    {
      img: "images/teamMembers/abdullah khan_ CONTRACTOR.jfif",
      name: "ABDULLAH KHAN",
      position: "CONTRACTOR",
    },
    {
      img: "images/teamMembers/trilok_contractor.jfif",
      name: "TRILOK",
      position: "CONTRACTOR",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center",
        padding: "40px",
        background: "black",
      }}
    >
      <div>
        <h1 style={{ fontWeight: "bold", color: "white" }}>Team</h1>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {teamMates.map((item) => (
          <div
            class="card"
            style={{
              width: "25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            {item.link ? (
              <a href={item.link} target="blank">
                <img
                  src={item.img}
                  class="round-image card-img-top"
                  alt="..."
                />
                <div class="card-body" style={{ color: "white" }}>
                  <h5 class="card-title">{item.name}</h5>
                  <p class="card-text">{item.position}</p>
                </div>
              </a>
            ) : (
              <>
                <img src={item.img} class="round-image card-img-top" alt="..." />
                <div class="card-body" style={{ color: "white" }}>
                  <h5 class="card-title">{item.name}</h5>
                  <p class="card-text">{item.position}</p>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamTemplate;
