import React from "react";
import "./AboutUsTemplate.css";
import Dialog from "@mui/material/Dialog";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import { List } from "@mui/material";
function AboutUsTemplate() {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <div
        className="background-image-container "
        style={{
          paddingTop: "90px",
          paddingBottom: "90px",
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            color: "black",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "30px",
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>How it works?</h1>
          <div style={{ display: "flex", gap: "30px" }} className="flow">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                borderRadius: "50%",
                background: "white",
                width: "6rem",
              }}
            >
              <h6>
                Meet <br /> & <br /> Greet
              </h6>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                borderRadius: "50%",
                background: "white",
                width: "6rem",
              }}
            >
              <h6>
                Idea <br /> & <br />
                concept
              </h6>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                borderRadius: "50%",
                width: "6rem",
                background: "white",
              }}
            >
              <h6>
                Design <br /> & <br /> create
              </h6>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                borderRadius: "50%",
                width: "6rem",
                background: "white",
              }}
            >
              <h6>
                Build <br /> & <br /> Install
              </h6>
            </div>
          </div>
          <TouchAppOutlinedIcon
            style={{
              cursor: "pointer",
              height: "61px",
              width: "69px",
              color: "white",
            }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        {open && (
          <>
            <Dialog
              onClose={() => setOpen(false)}
              open={open}
              className="MuiDialog-paper"
              style={{ boxShadow: "none", background: "none" }}
            >
              <button
                variant="outlined"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                }}
                onClick={() => setOpen(false)}
              >
                <h1 style={{ fontWeight: "bold" }}>X</h1>
              </button>
              <div style={{ background: "white", padding: "10px" }}>
                <h1 style={{ fontWeight: "bold" }}>Contact Us</h1>

                <ul class="list-group">
                  <li
                    class="list-group-item"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <h3>Address: </h3>
                    <h5>
                      MPR Arcade,71 Jubliee Enclave, Hitec City Hyderabad,
                      500081.
                    </h5>
                  </li>
                  <li
                    class="list-group-item"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <h3>Phone No.: </h3>
                    <h5>+91 7799662477, +91 8099611980</h5>
                  </li>
                  <li
                    class="list-group-item"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <h3>Email: </h3>
                    <h5>Team@jrayasarchitects.com</h5>
                  </li>

                  <li
                    class="list-group-item"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <h3>Website: </h3>
                    <h5> www.jrayasarchitects.com</h5>
                  </li>
                </ul>

                <h5></h5>
              </div>
            </Dialog>
          </>
        )}
        <div
          style={{
            display: "flex",
            color: "white",
            width: "30rem",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            className="card text-bg-dark custom-card"
            style={{ margin: "3px" }}
          >
            <img
              src="images/AboutUs/4.dining.jpg"
              className="card-img custom-image"
              alt="..."
            />
            <div
              className="card-img-overlay"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="play-icon-container">
                <div className="play-icon-container">
                  <a
                    target="blank"
                    className="custom-link"
                    underline="none"
                    href="https://www.youtube.com/@jrayasarchitects"
                    color="white"
                  >
                    <PlayCircleFilledWhiteRoundedIcon
                      className="animated-icon"
                      style={{ width: "72px", height: "72px", color: "white" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "black",
          color: "white",
          display: "flex",
          gap: "20px",
          padding: "20px",
        }}
        className="check"
      >
        <div style={{ width: "100%" }}>
          <h1>About US</h1>
          <p style={{ textAlign: "start" }}>
            At <i style={{ fontWeight: "bold" }}>JRAYAS ARCHITECTS </i> we offer
            a wide range of architecture and interior design services tailored
            to meet the unique needs and aspirations of our clients. Our team of
            experienced architects and interior designers work collaboratively
            to turn your vision into reality.
          </p>
        </div>
        <div style={{ background: "black", color: "white", width: "100%" }}>
          <h1 style={{ fontWeight: "bold" }}>Contact Us:</h1>

          <table class="table table-dark table-black">
            <tbody>
              <tr>
                <th scope="row">
                  <LocationOnOutlinedIcon />
                </th>
                <td>
                  MPR Arcade,71 Jubliee Enclave, Hitec City Hyderabad, 500081.
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <CallOutlinedIcon />
                </th>
                <td>+91 7799662477, +91 8099611980</td>
              </tr>
              <tr>
                <th scope="row">
                  <EmailOutlinedIcon />{" "}
                </th>
                <td>Team@jrayasarchitects.com</td>
              </tr>
              <tr>
                <th scope="row">
                  <LanguageOutlinedIcon />{" "}
                </th>
                <td>www.jrayasarchitects.com</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: "fit-content",
            textAlign: "justify",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>Our Services</h1>
          <ul>
            <li>Architecture</li>
            <li>Interior</li>
            <li>Construction</li>
            <li>PMC</li>
            <li>Landscape</li>
            <li>Turnkey</li>
          </ul>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "black",
          color: "white",
          padding: "60px",
          marginBottom: "130px",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
        className="flow"
      >
        <img
          src="../../images/home/JA.png"
          alt="Bootstrap"
          width="350px"
          style={{ marginBottom: "5px" }}
        ></img>
        <div style={{ display: "flex", gap: "10px" }}>
          <a
            className="custom-link"
            underline="none"
            href="https://www.facebook.com/share/QWQhKrfTJvL4cKb9/?mibextid=qi2Omg"
            target="blank"
          >
            <FacebookOutlinedIcon style={{ width: "40px", height: "40px" }} />
          </a>
          <a
            className="custom-link"
            underline="none"
            href="https://wa.me/+918099611980"
            target="blank"
          >
            <WhatsAppIcon style={{ width: "40px", height: "40px" }} />
          </a>
          <a
            className="custom-link"
            underline="none"
            href="https://www.instagram.com/jrayasarchitects?igsh=dGFrNjVlMTVlNXNj"
            target="blank"
          >
            <InstagramIcon style={{ width: "40px", height: "40px" }} />
          </a>
          <a
            className="custom-link"
            underline="none"
            href="https://www.youtube.com/@jrayasarchitects"
            target="blank"
          >
            <PlayCircleFilledWhiteRoundedIcon
              style={{ width: "40px", height: "40px" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUsTemplate;
