import React from "react";
import Dialog from "@mui/material/Dialog";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
//import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";

import { List } from "@mui/material";
function ContactUsTemplate() {
  const [open, setOpen] = React.useState(false);
  return (
    <div style={{ background: "black", color: "white", width: "100%" ,padding:"90px"}}>
      <h1 style={{ fontWeight: "bold" }}>Contact Us:</h1>

      <table class="table table-dark   table-sm table-black">
        <tbody>
          <tr>
            <th scope="row">
              <LocationOnOutlinedIcon />
            </th>
            <td>
              MPR Arcade,71 Jubliee Enclave, Hitec City Hyderabad, 500081.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <CallOutlinedIcon />
            </th>
            <td>+91 7799662477, +91 8099611980</td>
          </tr>
          <tr>
            <th scope="row">
              <EmailOutlinedIcon />{" "}
            </th>
            <td>Team@jrayasarchitects.com</td>
          </tr>
          <tr>
            <th scope="row">
              <LanguageOutlinedIcon />{" "}
            </th>
            <td>www.jrayasarchitects.com</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default ContactUsTemplate;
