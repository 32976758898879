import React from "react";
import "./TotalEstimationTemplate.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
export default function TotalEstimationTemplate() {
  
const totalEstimation = [
  { count: "15,000", key: "Hours of works" },
  { count: "300 +", key: "Complete Projects" },
  { count: "300 +", key: "Consultations" },
  { count: "50 +", key: "Constructions" },
  { count: "50 +", key: "Interiors" },
];
  return (
    <div
      style={{
        backgroundImage: `url("images/Services/2.architecture.jpg")`,
        backgroundSize: "cover", // Optional: Adjusts background image to cover the component
        backgroundPosition: "bottom", // Optional: Centers the image
      }}
    >
      <div style={{ background: "rgba(0, 0, 0, 0.5)" }}>
        <div
          id="carouselExampleCaptions"
          style={{ padding: "90px" }}
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators" style={{ bottom: "45px" }}>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              style={{
                borderRadius: "74px",
                borderTop: "0px",
                borderBottom: "0px",
                width: "8px",
                height: "8px",
              }}
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              style={{
                borderRadius: "74px",
                borderTop: "0px",
                borderBottom: "0px",
                width: "8px",
                height: "8px",
              }}
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              style={{
                borderRadius: "74px",
                borderTop: "0px",
                borderBottom: "0px",
                width: "8px",
                height: "8px",
              }}
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="3000">
              <FormatQuoteIcon
                style={{
                  transform: "rotate(180deg)",
                  fontSize: "45px",
                  color: "white",
                }}
              />
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  fontStyle: "italic",
                }}
              >
                I don't think that architecture is only about shelter.
                <br />
                It should be able to excite you, to calm you, to make you
                think."
              </h3>
              <h5
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                - Zaha Hadid
              </h5>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <FormatQuoteIcon
                style={{
                  transform: "rotate(180deg)",
                  fontSize: "45px",
                  color: "white",
                }}
              />
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  fontStyle: "italic",
                }}
              >
                This quote encapsulates Mies van der Rohe's philosophy of
                minimalism and simplicity in design, which has had a profound
                influence on modern architecture and design. It emphasizes the
                idea that often, by simplifying and removing excess, the essence
                and beauty of a design can shine through.
              </h3>
              <h5
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                - Mies van der Rohe's
              </h5>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <FormatQuoteIcon
                style={{
                  transform: "rotate(180deg)",
                  fontSize: "45px",
                  color: "white",
                }}
              />
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  fontStyle: "italic",
                }}
              >
                Space is the breath of art."
              </h3>
              <h5
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                - Frank Lloyd Wrigh
              </h5>
            </div>
          </div>
        </div>
        <div className=" text-center  " style={{ paddingTop: "100px" }}>
          <div className="row">
            {totalEstimation.map((item) => (
              <div className="col">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      color: "white",

                      fontSize: "xxx-large",
                    }}
                  >
                    {item.count}
                  </h5>
                  <a
                    href="#"
                    className="btn text-white"
                    style={{
                      fontSize: "14px",
                      letterSpacing: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.key}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
