import React from "react";
import { useLocation } from "react-router-dom";
export const IndividualServiceTemplate = () => {
  const location = useLocation();

  // Access the pathname from the location object
  const currentPathname = location.pathname;
  const [currentService, setCurrentService] = React.useState([]);
  // Extract the last segment of the pathname
  const lastSegment = currentPathname.split("/").pop();
  const services = [
    {
      path: "architecturalService",
      title: "Architectural Services",
      caption: "Crafting the Built Environment",

      image: "/images/Services/2.architecture.jpg",
      content:
        "At JRAYAS ARCHITECTS we're passionate about shaping the world through innovative and sustainable architectural solutions. With a commitment to design excellence, functionality, and aesthetics, we provide a wide range of architectural services that turn visions into reality. Explore how our team of experienced architects can bring your architectural project to life.",
      subHeading1: {
        heading: "Why Choose Our Architectural Services?",
        content: [
          {
            sideHeading: "Expertise: ",
            content:
              " Our architects are dedicated to creating functional, aesthetically pleasing structures. With years of experience and a deep understanding of architectural principles, we provide the expertise your project deserves",
          },
          {
            sideHeading: "Customization: ",
            content:
              "We recognize that each project is unique. Our architectural services are tailored to your specific requirements, style, and goals.",
          },
          {
            sideHeading: "Sustainability: ",
            content:
              "We are committed to sustainable design practices, incorporating green principles into our projects to reduce environmental impact and energy consumption.",
          },
        ],
      },
      subHeading2: {
        heading: "Our Architectural Services",
        content: [
          {
            heading: "Architectural Design: ",
            content:
              "Our core service revolves around designing innovative structures that balance form, function, and beauty. From concept development to construction documentation, we guide your project through every stage.",
          },
          {
            heading: "Feasibility Studies: ",
            content:
              "Before the first brick is laid, we conduct thorough feasibility studies to assess the practicality of your project, taking into account site constraints, budgets, and regulatory requirements.",
          },
          {
            heading: "Project Management: ",
            content:
              "We take the burden of managing your project off your shoulders. Our project management team ensures that schedules, budgets, and quality control are maintained, allowing you to focus on your vision.",
          },
          {
            heading: "Sustainable Design: ",
            content:
              "We believe in responsible architecture. Our architects integrate eco-friendly practices, energy-efficient solutions, and sustainable materials into every project.",
          },
          {
            heading: "Renovations and Remodeling: ",
            content:
              "Whether you're looking to restore a historical landmark or transform an existing space, our renovation and remodeling services breathe new life into structures.",
          },
        ],
      },
      subHeading3: {
        heading: "The Architectural Journey",
        content: "Our comprehensive architectural process includes:",
        process: [
          {
            heading: "Initial Consultation: ",
            content:
              "We sit down with you to understand your vision, goals, and project requirements.",
          },
          {
            heading: "Design and Planning: ",
            content:
              "Our architects collaborate closely with you to create a customized architectural plan that aligns with your vision and objectives.",
          },
          {
            heading: "Construction Documentation: ",
            content:
              "We provide detailed construction documents to ensure your project is executed with accuracy and quality, from blueprints to material specifications.",
          },
          {
            heading: "Project Management: ",
            content:
              "Our team oversees your project, managing schedules, budgets, and quality control, to ensure a successful outcome.",
          },
          {
            heading: "Completion and Handover: ",
            content:
              "We take pride in handing over your completed project on time, within budget, and ready for you to enjoy",
          },
        ],
      },
      subHeading4: {
        heading: "Explore Our Portfolio",
        content:
          "Visit our Architectural Portfolio to witness the diverse range of successful architectural projects we've undertaken. From residential marvels to commercial wonders, each project is a testament to our commitment to innovation and design excellence. Ready to embark on your architectural journey? Contact Us today to discuss your architectural project.At JRAYAS ARCHITECTS , we're dedicated to turning architectural visions into reality.",
      },
    },
    {
      path: "interiorService",
      title: "Interior Services",
      caption: "Transforming Spaces, Enhancing Lives",
      image: "/images/Services/interiors.jpg",
      content:
        "At JRAYAS ARCHITECTS ,we believe that interiors should reflect your personality, improve functionality, and evoke emotions. Our interior design services are a celebration of creativity, functionality, and aesthetics, dedicated to creating environments that inspire and elevate the human experience. Discover how our team of experienced interior designers can bring your vision to life",
      subHeading1: {
        heading: "Why Choose Our Interior Design Services?",
        content: [
          {
            sideHeading: "Expertise: ",
            content:
              "Our interior designers are passionate about creating functional, aesthetically pleasing spaces. With years of experience and a deep understanding of design principles, we provide the expertise your project deserves.",
          },
          {
            sideHeading: "Customization: ",
            content:
              "We understand that every project is unique. Our interior design services are tailored to your specific requirements, style, and goals.",
          },
          {
            sideHeading: "Aesthetic Excellence: ",
            content:
              "We have an eye for detail and a commitment to design excellence, ensuring that your space is not only beautiful but also functional.",
          },
        ],
      },
      subHeading2: {
        heading: "Our Interior Design Services",
        content: [
          {
            heading: "Space Planning: ",
            content:
              "We optimize the layout of your space, ensuring it's both functional and aesthetically pleasing. Whether it's a home, office, or retail space, we create layouts that enhance the way you live and work.",
          },
          {
            heading: "Interior Styling: ",
            content:
              "We bring your unique style to life with custom interior designs, tailored to your taste and lifestyle. Our designs evoke the feeling and personality that resonate with you.",
          },
          {
            heading: "Furniture and Fixture Selection: ",
            content:
              "Our team helps you select the perfect furniture, fixtures, and finishes to complete your interior design. We curate a selection that aligns with your vision.",
          },
          {
            heading: "Color and Material Selection: ",
            content:
              "From paint colors to flooring materials, we guide you in selecting the right elements to create a cohesive and harmonious space. We believe that every color and material has a purpose.",
          },
        ],
      },
      subHeading3: {
        heading: "The Interior Design Journey",
        content: "Our comprehensive interior design process includes:",
        process: [
          {
            heading: "Initial Consultation: ",
            content:
              "We sit down with you to understand your vision, needs, and preferences.",
          },
          {
            heading: "Concept Development: ",
            content:
              "We work with you to define the concept of your project, ensuring it aligns with your goals and vision.",
          },
          {
            heading: "Design and Planning: ",
            content:
              "Our interior designers create a customized design plan that reflects your personality and objectives.",
          },
          {
            heading: "Selection and Procurement: ",
            content:
              "We source, select, and procure the necessary furnishings and materials to bring your design to life.",
          },
          {
            heading: "Implementation: ",
            content:
              "Our team takes care of the implementation process, overseeing construction and ensuring your design is executed with precision.",
          },
        ],
      },
      subHeading4: {
        heading: "Explore Our Portfolio",
        content:
          "Visit our [Interior Design Portfolio] to see examples of the diverse range of successful interior design projects we've undertaken. Each project is a unique showcase of our commitment to innovation and design excellence. Ready to embark on your interior design journey? Contact Us today to discuss your interior design project. At JRAYAS ARCHITECTS, we're dedicated to turning interior visions into reality.",
      },
    },
    {
      title: "Modular Interiors",
      caption: "Versatile Design for Your Dynamic Lifestyle",
      path: "modularInteriorService",
      image: "/images/Services/modular.jpg",
      content:
        "JRAYAS ARCHITECTS , we understand that the way we live, work, and interact with our spaces is constantly evolving. That's why we offer a range of innovative modular interior solutions designed to adapt to your ever-changing needs and preferences. Our modular interiors combine flexibility, functionality, and style, allowing you to transform your space with ease",
      subHeading1: {
        heading: "Why Choose Modular Interiors?",
        content: [
          {
            sideHeading: "Adaptability: ",
            content:
              "With modular interiors, your space can evolve as your needs change. Whether you need a home office today and a cozy reading nook tomorrow, our solutions can be easily reconfigured to suit your lifestyle.",
          },
          {
            sideHeading: "Space Optimization: ",
            content:
              "Make the most of every square inch. Our modular designs are created to maximize your space's potential, providing smart storage solutions and multifunctional furniture that optimize your living or working area.",
          },
          {
            sideHeading: "Sustainability: ",
            content:
              "Modular interiors are an eco-friendly choice. By reducing the need for constant renovations and minimizing waste, you're making a positive impact on the environment.",
          },
        ],
      },
      subHeading2: {
        heading: "Our Modular Interior Services",
        content: [
          {
            heading: "Modular Furniture: ",
            content:
              "Discover a range of versatile, customizable, and aesthetically pleasing modular furniture solutions. From modular sofas and storage units to transformable tables and shelving, our designs are built to fit seamlessly into your space.",
          },
          {
            heading: "Home Office Solutions: ",
            content:
              "Create a functional home office that meets your unique needs. Our modular office solutions include adjustable desks, storage units, and ergonomic seating for maximum productivity.",
          },
          {
            heading: "Kitchen and Bathroom: ",
            content:
              "Revolutionize your kitchen and bathroom with modular designs that optimize storage, create efficient workspaces, and enhance the overall look of these essential areas.",
          },
          {
            heading: "Wardrobes and Closets: ",
            content:
              "Say goodbye to cluttered closets. Our modular wardrobe systems provide organized, efficient storage for your clothing and accessories, ensuring that everything has its place.",
          },
          {
            heading: "Custom Modular Solutions: ",
            content:
              "If you have a specific idea in mind, our team can work with you to create custom",
          },
        ],
      },
      subHeading4: {
        heading: "Explore the Possibilities",
        content:
          "Visit our portfolio to see how our modular solutions have transformed spaces into functional and aesthetically pleasing environments. Whether you're looking to maximize the functionality of a small apartment, optimize your office space, or create a flexible living area, our modular interiors can be tailored to your unique needs and style. Contact Us today to discuss how modular interiors can enhance your space and simplify your life. Our team is ready to work with you to create a modular interior design that seamlessly integrates with your lifestyle. At JRAYAS ARCHITECTS ,we believe that adaptable design is the key to a more comfortable and harmonious living and working environment. Discover the limitless possibilities of modular interiors today!",
      },
    },
    {
      title: "Landscape Services",
      caption: "Transforming the Outdoors",
      path: "landscapeService",
      image: "/images/Services/landscape.jpg",
      content:
        "At JRAYAS ARCHITECTS ,we believe that the great outdoors should be just as captivating as the indoors. Our landscape works are a testament to our commitment to creating outdoor spaces that harmonize with the natural environment and enhance the beauty of any setting. Explore our portfolio of landscape projects to see how we can transform your outdoor space.",
      subHeading5: {
        content: [
          {
            sideHeading: "Landscape Design and Implementation ",
            content:
              "Our landscape design services encompass everything from initial concept development to the final implementation.We work closely with you to create a customized plan that reflects your vision, preferences, and the unique characteristics of your outdoor space. Our team of landscape architects, horticulturists, and designers collaborates to bring your outdoor dreams to life.",
          },
          {
            sideHeading: "Residential Landscaping ",
            content:
              "Our residential landscaping services can turn your backyard into an oasis, providing you with an escape right at home. Whether you're looking for a peaceful garden, a functional outdoor kitchen, or a vibrant play area for your family, our designs are tailored to your lifestyle.",
          },
          {
            sideHeading: "Commercial Landscaping ",
            content:
              "For businesses and public spaces, our commercial landscaping projects are designed to make a lasting impression. From corporate campuses to hospitality venues and urban parks, we create outdoor environments that leave visitors inspired and at ease.",
          },
          {
            sideHeading: "Sustainable Landscaping ",
            content:
              "We prioritize sustainability in our landscape projects, utilizing environmentally friendly practices and materials. From water-efficient irrigation systems to native plant selections, we aim to minimize the environmental impact of our landscaping work",
          },
          {
            sideHeading: "Maintenance and Ongoing Care ",
            content:
              "We offer comprehensive maintenance services to keep your landscape looking pristine throughout the seasons. Our skilled team can handle regular mowing, pruning, plant care, and other essential tasks to ensure your outdoor space remains a source of beauty and relaxation.",
          },
          {
            sideHeading: "Your Outdoor Vision Awaits ",
            content:
              "Your outdoor space has the potential to be an extension of your home or business, a place of beauty, and a source of inspiration. Explore our portfolio to witness the transformative power of our designs and services. Ready to discuss your landscape project? Contact Us today to start the journey toward creating an outdoor space that perfectly suits your needs and complements your surroundings. At JRAYAS ARCHITECTS, we're dedicated to turning outdoor dreams into reality.",
          },
        ],
      },
      subHeading3: {
        heading: "Landscape Features and Elements",
        content:
          "We specialize in incorporating a wide range of landscape features and elements to create truly unique outdoor spaces:",
        process: [
          {
            heading: "Hardscape Design: ",
            content:
              "Our expertise extends to designing patios, pathways, retaining walls, and outdoor structures that seamlessly integrate into the natural surroundings.",
          },
          {
            heading: "Plant Selection and Installation: ",
            content:
              "We curate a selection of plants, trees, and shrubs that thrive in your specific climate and soil conditions, ensuring a lush and vibrant landscape.",
          },
          {
            heading: "Water Features: ",
            content:
              "Whether it's a tranquil pond, a bubbling fountain, or a cascading waterfall, our water features add a sense of serenity to any outdoor space.",
          },
          {
            heading: "Lighting Design: ",
            content:
              "Our landscape lighting solutions enhance the beauty of your outdoor space while providing safety and security after dark.",
          },
        ],
      },
    },
    {
      title: "Building Construction ",
      caption: "Crafting Your Vision Into Reality",
      path: "buildingConstructionService",
      image: "/images/Services/construction.jpg",
      content:
        "At JRAYAS ARCHITECTS, we specialize in building construction that marries innovation, quality, and functionality. Our unwavering commitment to excellence has made us a trusted partner for a diverse range of construction projects. Whether you're planning to build a residential home, a commercial facility, or an industrial complex, our construction services are designed to bring your vision to life.",
      subHeading1: {
        heading: "Why Choose Modular Interiors?",
        content: [
          {
            sideHeading: "Expertise: ",
            content:
              "Our team of experienced architects, engineers, project managers, and skilled craftsmen are dedicated to delivering projects of the highest quality",
          },
          {
            sideHeading: "Customization: ",
            content:
              "We understand that every project is unique. We work closely with you to tailor our construction services to meet your specific requirements and goals.",
          },
          {
            sideHeading: "Efficiency: ",
            content:
              "We're committed to completing your project on time and within budget without compromising on quality",
          },
        ],
      },
      subHeading2: {
        heading: "Our Building Construction Services",
        content: [
          {
            heading: "Residential Construction: ",
            content:
              "From single-family homes to multi-unit developments, our residential construction services bring your dream home to life. We offer everything from architectural design and site preparation to the final finishes that make a house a home.",
          },
          {
            heading: "Commercial Construction: ",
            content:
              "We have a proven track record of delivering functional and aesthetically pleasing commercial spaces. Whether it's a new office building, a retail facility, or a restaurant, our commercial construction services are designed to help your business thrive.",
          },
          {
            heading: "Industrial Construction: ",
            content:
              "Our industrial construction projects encompass manufacturing facilities, warehouses, distribution centers, and more. We provide the infrastructure that supports the backbone of industry",
          },
          {
            heading: "Renovations and Remodeling: ",
            content:
              "We breathe new life into existing structures through our renovation and remodeling services. Enhance the value and functionality of your property with our expertise.",
          },
          {
            heading: "Sustainable Building: ",
            content:
              "We are committed to green building practices. Our sustainable construction methods help reduce environmental impact while saving you energy and resources in the long run.",
          },
        ],
      },
      subHeading3: {
        heading: "The Building Construction Journey",
        content: "Our comprehensive building process includes:",
        process: [
          {
            heading: "Design and Planning: ",
            content:
              "We start with detailed design and planning to ensure that every aspect of your project is carefully considered.",
          },
          {
            heading: "Permitting and Approvals: ",
            content:
              "We manage the permitting process and secure the necessary approvals to keep your project on track.",
          },
          {
            heading: "Construction and Execution: ",
            content:
              "Our skilled craftsmen and project managers work together to bring the design to life, with a focus on quality, safety, and efficiency.",
          },
          {
            heading: "Quality Assurance: ",
            content:
              "Quality is a top priority. We perform regular inspections and quality checks to ensure that every aspect of the project meets our rigorous standards.",
          },
          {
            heading: "Completion and Handover: ",
            content:
              "We take pride in handing over your completed project on time, within budget, and ready for you to occupy or utilize.",
          },
        ],
      },
      subHeading4: {
        heading: "Your Vision, Our Expertise",
        content:
          "Our building construction services are driven by your vision. We listen to your needs, collaborate closely with you, and execute with precision to turn your concept into a reality. Our Portfolio showcases a diverse range of successful projects, offering a glimpse of our capabilities.Ready to discuss your building construction project? Contact Us today to begin the journey toward your construction goals.At JRAYAS ARCHITECTS, we're committed to building excellence.",
      },
    },
    {
      title: "PMC",
      caption:
        "Elevating Your Vision: Project Management Consultancy for Construction and Interiors",
      path: "pmcService",
      image: "/images/Services/pmc.jpg",
      content:
        "At JRAYAS ARCHITECTS, we understand that successful construction and interior projects require meticulous planning, precise execution, and expert oversight. Our Project Management Consultancy (PMC) services are your dedicated partners in ensuring the seamless realization of your vision. Whether you're embarking on a new construction project, a renovation, or an interior design endeavor, our experienced team of project managers, engineers, and design professionals is here to guide you to success.",
      subHeading1: {
        heading: "Why Choose Our PMC Services?",
        content: [
          {
            sideHeading: "Comprehensive Expertise: ",
            content:
              "We combine construction management and interior design expertise to deliver holistic solutions for your projects.",
          },
          {
            sideHeading: "Tailored Solutions: ",
            content:
              "Every project is unique, and our PMC services are customized to your specific needs, objectives, and budget.",
          },
          {
            sideHeading: "Efficiency and Quality: ",
            content:
              "We are committed to delivering your project on time and within budget while upholding the highest standards of quality.",
          },
        ],
      },
      subHeading2: {
        heading: "Our PMC Services in Construction and Interiors",
        content: [
          {
            heading: "Project Planning: ",
            content:
              "We collaborate with you to define project goals, objectives, and design specifications. Our team assists in creating a comprehensive project plan, including timelines, milestones, and resource allocation.",
          },
          {
            heading: "Risk Management: ",
            content:
              "We identify potential project risks, whether in construction or interior design, and develop strategies to mitigate them, ensuring smooth progress.",
          },
          {
            heading: "Industrial Construction: ",
            content:
              "Our industrial construction projects encompass manufacturing facilities, warehouses, distribution centers, and more. We provide the infrastructure that supports the backbone of industry",
          },
          {
            heading: "Quality Control: ",
            content:
              "Our quality assurance processes involve regular inspections, audits, and performance evaluations to maintain high standards of workmanship and materials.",
          },
          {
            heading: "Cost Management: ",
            content:
              "We help you control costs by providing accurate budget estimates, ongoing cost tracking, and financial reporting throughout the project's lifecycle.",
          },
          {
            heading: "Contract Management: ",
            content:
              "Our experts assist with contract negotiations, administration, and dispute resolution to protect your interests.",
          },
          {
            heading: "Schedule Management: ",
            content:
              "We create and maintain a detailed project schedule, ensuring that work progresses according to plan and deadlines are met.",
          },
          {
            heading: "Health and Safety Compliance: ",
            content:
              "We prioritize safety, whether it's on the construction site or during interior renovations. Our health and safety experts ensure compliance with all regulations and best practices.",
          },
        ],
      },
      subHeading3: {
        heading: "The PMC Process",
        content: "Our comprehensive PMC process includes:",
        process: [
          {
            heading: "Initial Consultation: ",
            content:
              "We understand your project goals, whether it's a construction project or an interior design endeavor.",
          },
          {
            heading: "Project Initiation: ",
            content:
              "We define project scope, objectives, and create a customized plan that addresses both construction and interior design aspects.",
          },
          {
            heading: "Execution and Oversight: ",
            content:
              "Our team closely monitors the project's progress, ensuring work aligns with your objectives and quality standards for both construction and interiors.",
          },
          {
            heading: "Communication: ",
            content:
              "We provide regular progress reports to keep you informed about the project's status and any issues that need attention.",
          },
          {
            heading: "Project Closure: ",
            content:
              "We ensure a smooth handover of the completed project, whether it's a construction project or interior design project, providing you with all necessary documentation and final approvals.",
          },
        ],
      },
      subHeading4: {
        heading: "Your Vision, Our Expertise",
        content:
          "Our PMC services are centered on your vision and goals, whether they pertain to construction, interiors, or a seamless blend of both. We provide the necessary expertise, oversight, and guidance to turn your project into a success story. Visit our [Project Portfolio] to see examples of our successful PMC projects that seamlessly integrate construction and interiors. Ready to discuss your project? Contact Us today to begin the journey toward a project that is expertly managed, whether it encompasses construction or interior design. At JRAYAS ARCHITECTS, we are committed to realizing your vision, from concept to completion.",
      },
    },
  ];
  const check = services.filter((item) => {
    return item.path === lastSegment;
  });
  React.useEffect(() => {
    setCurrentService(check);
  }, [lastSegment]);

  return (
    <>
      {currentService?.length > 0 && (
        <div className="bg-dark " style={{ padding: "40px 0px 40px 0px " }}>
          {currentService?.map((service) => (
            <div
              
              style={{
                backgroundImage: `url(${service.image})`,
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                padding: "50px",
                backgroundPosition:"center"
    
              }}
            >
              <div className="row" style={{background:"rgba(0, 0, 0, 0.5)"}}>
                <div className="col">
                  <div
                    style={{
                      color: "white",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {service?.title}
                    </h1>
                    <h5>{service?.caption}</h5>
                    <p style={{ color: "white", textAlign: "left" }}>
                      {service?.content}
                    </p>
                    {service?.subHeading1 && (
                      <div style={{ padding: "28px", color: "white" }}>
                        <h3
                          style={{
                            color: "white",
                            textAlign: "start",
                            fontWeight: "bold",
                          }}
                        >
                          {service?.subHeading1?.heading}
                        </h3>
                        {service?.subHeading1?.content?.map((subConcept) => (
                          <ul
                            style={{
                              color: "white",
                              textAlign: "start",
                            }}
                          >
                            <li>
                              <h4>{subConcept?.sideHeading} </h4>
                              <p>{subConcept?.content}</p>
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                    {service?.subHeading2 && (
                      <div style={{ padding: "28px", color: "white" }}>
                        <h3
                          style={{
                            color: "white",
                            textAlign: "start",
                            fontWeight: "bold",
                          }}
                        >
                          {service?.subHeading2?.heading}
                        </h3>
                        {service?.subHeading2?.content?.map((subConcept) => (
                          <ul
                            style={{
                              color: "white",
                              textAlign: "start",
                            }}
                          >
                            <li>
                              <h4>{subConcept?.heading} </h4>
                              <p>{subConcept?.content}</p>
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                    {service?.subHeading3 && (
                      <div style={{ padding: "28px", color: "white" }}>
                        <h3
                          style={{
                            textAlign: "start",
                            fontWeight: "bold",
                          }}
                        >
                          {service?.subHeading3?.heading}
                        </h3>
                        <h4
                          style={{
                            textAlign: "start",
                          }}
                        >
                          {service?.subHeading3?.content}
                        </h4>
                        {service?.subHeading3?.process?.map(
                          (subConcept, index) => (
                            <div>
                              <ul
                                style={{
                                  textAlign: "start",
                                }}
                              >
                                <li>
                                  <h5>{subConcept?.heading} </h5>
                                  <p>{subConcept?.content}</p>
                                </li>
                              </ul>
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {service?.subHeading4 && (
                      <div
                        style={{
                          padding: "28px",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          {service?.subHeading4?.heading}
                        </h3>
                        <p>{service?.subHeading4?.content}</p>
                      </div>
                    )}
                    {service?.subHeading5 && (
                      <div
                        style={{
                          padding: "28px",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        {service?.subHeading5?.content?.map((subContent) => (
                          <>
                            <h3
                              style={{
                                textAlign: "left",
                                fontWeight: "bold",
                              }}
                            >
                              {subContent?.sideHeading}
                            </h3>
                            <p>{subContent?.content}</p>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default IndividualServiceTemplate;
